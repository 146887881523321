<template>
    <div class="cookie-banner">
        <p class="cookie-banner__title">We use cookies!</p>
        <div class="cookie-banner__description">
            Hi, AskGPT uses essential cookies to ensure its proper operation
            and tracking cookies to understand how you interact with it. The
            latter will be set only after consent.
        </div>
        <div class="cookie-banner__buttons">
            <button type="button" class="cookie-banner__button bg-[#479F82]" @click="$emit('accept-cookie')">
                Accept All
            </button>
            <button type="button" class="cookie-banner__button bg-[#525252]" @click="$emit('reject-cookie')">
                Reject All
            </button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.cookie-banner {
    @apply fixed z-[1000] bottom-[24px] right-[50%] max-w-[348px] w-full px-[20px] py-[24px] rounded-[20px] bg-[#2E2E2E];
    transform: translate(50%, -20%);

    @screen lg {
        @apply bottom-[24px] right-[24px];
        transform: translate(0, 0);
    }
}

.cookie-banner__title {
    @apply mb-[14px] font-[600] text-[16px] leading-[19px] text-[#FFFFFF];
}

.cookie-banner__description {
    @apply mb-[16px] font-[500] text-[14px] leading-[18px] text-[#FFFFFF];
}

.cookie-banner__buttons {
    @apply flex justify-between gap-[12px];
}

.cookie-banner__button {
    @apply w-full py-[10px] rounded-[16px] font-[500] text-[16px] leading-[19px] text-[#FFFFFF];
}
</style>
